const tailwindColors = require('tailwindcss/colors');

const gkiGrey = {
  50: '#EAEFF5',
  100: '#D6DBE3',
  150: '#C2C8D0',
  200: '#AEB4BE',
  300: '#9AA0AB',
  400: '#868D99',
  450: '#737986',
  500: '#5F6674',
  600: '#4B5261',
  700: '#373E4F',
  800: '#232B3C',
  900: '#0F172A',
  DEFAULT: '#868D99',
};
const gkiGreyDark = {
  900: '#DCE4ED',
  800: '#CDD5DF',
  700: '#BDC6D1',
  600: '#BDC6D1',
  500: '#AEB8C4',
  450: '#9FA9B6',
  400: '#8F9AA8',
  300: '#808B9A',
  200: '#616D7E',
  150: '#525F71',
  100: '#425063',
  50: '#334155',
  DEFAULT: '#8690A4',
};

const baseColors = {
  gray: tailwindColors.zinc,
  orange: tailwindColors.orange,
  coolGray: tailwindColors.gray,
  red: tailwindColors.red,
  yellow: tailwindColors.amber,
  green: tailwindColors.green,
  blue: tailwindColors.blue,
  indigo: tailwindColors.indigo,
  purple: tailwindColors.violet,
  pink: tailwindColors.pink,
  teal: tailwindColors.teal,
};

const brandColors = {
  ketoneBlue: {
    900: '#04292F',
    800: '#053942',
    700: '#096272',
    600: '#0C8BA1',
    500: '#10B3CF',
    450: '#33C7E1',
    400: '#55DBF2',
    300: '#B3EFF9',
    200: '#BEF1FA',
    150: '#C9F4FB',
    100: '#D3F6FC',
    50: '#DEF8FD',
    DEFAULT: '#096272',
  },
  ketoneBlueDark: {
    50: '#04292F',
    100: '#053942',
    150: '#096272',
    200: '#0C8BA1',
    300: '#10B3CF',
    400: '#29BCD5',
    450: '#42C5DB',
    500: '#5BCEE1',
    600: '#73D6E8',
    700: '#8CDFEE',
    800: '#A5E8F4',
    900: '#BEF1FA',
    DEFAULT: '#29BCD5',
  },
  glucoseBrown: {
    900: '#1D1916',
    800: '#28231F',
    700: '#453C35',
    600: '#62554B',
    500: '#857366',
    450: '#9D8D82',
    400: '#B4A69D',
    300: '#DCD5D1',
    200: '#E1DBD8',
    150: '#E6E1DE',
    100: '#EBE7E5',
    50: '#F0EDEB',
    DEFAULT: '#B4A69D',
  },
  glucoseBrownDark: {
    50: '#1D1916',
    100: '#28231F',
    150: '#453C35',
    200: '#62554B',
    300: '#857366',
    400: '#928276',
    450: '#9F9187',
    500: '#ACA097',
    600: '#BAAEA7',
    700: '#C7BDB7',
    800: '#D4CCC8',
    900: '#E1DBD8',
    DEFAULT: '#9D8D82',
  },
  gkiGrey,
  gkiGreyDark,
  proteinGreen: {
    900: '#064E3B',
    700: '#047857',
    800: '#065F46',
    600: '#059669',
    500: '#10B981',
    400: '#34D399',
    300: '#51DBA7',
    200: '#6EE3B5',
    100: '#8AEBC2',
    50: '#A7F3D0',
    DEFAULT: '#34D399',
  },
  proteinGreenDark: {
    50: '#064E3B',
    100: '#047857',
    200: '#065F46',
    300: '#059669',
    400: '#10B981',
    500: '#34D399',
    600: '#51DBA7',
    700: '#6EE3B5',
    800: '#8AEBC2',
    900: '#A7F3D0',
    DEFAULT: '#10B981',
  },
  carbsBlue: {
    900: '#1E3A8A',
    800: '#1E40AF',
    700: '#1D4ED8',
    600: '#2563EB',
    500: '#3B82F6',
    400: '#60A5FA',
    300: '#78B3FB',
    200: '#90C0FC',
    100: '#A7CEFD',
    50: '#BFDBFE',
    DEFAULT: '#60A5FA',
  },
  carbsBlueDark: {
    50: '#1E3A8A',
    100: '#1E40AF',
    200: '#1D4ED8',
    300: '#2563EB',
    400: '#3B82F6',
    500: '#60A5FA',
    600: '#78B3FB',
    700: '#90C0FC',
    800: '#A7CEFD',
    900: '#BFDBFE',
    DEFAULT: '#3B82F6',
  },
  fatPink: {
    900: '#701A75',
    800: '#86198F',
    700: '#A21CAF',
    600: '#C026D3',
    500: '#D946EF',
    400: '#E879F9',
    300: '#EB8FFA',
    200: '#EFA5FC',
    100: '#F2BAFD',
    50: '#F5D0FE',
    DEFAULT: '#E879F9',
  },
  fatPinkDark: {
    50: '#701A75',
    100: '#86198F',
    200: '#A21CAF',
    300: '#C026D3',
    400: '#D946EF',
    500: '#E879F9',
    600: '#EB8FFA',
    700: '#EFA5FC',
    800: '#F2BAFD',
    900: '#F5D0FE',
    DEFAULT: '#D946EF',
  },
  ketoneBreath: {
    900: '#1A1122',
    800: '#261933',
    700: '#402A56',
    600: '#5A3A78',
    500: '#744B9B',
    400: '#A787C5',
    300: '#B498CD',
    200: '#C0AAD6',
    100: '#CDBBDE',
    50: '#D9CCE6',
    DEFAULT: '#A787C5',
  },
  ketoneBreathDark: {
    50: '#1A1122',
    100: '#261933',
    200: '#402A56',
    300: '#5A3A78',
    400: '#744B9B',
    500: '#A787C5',
    600: '#B498CD',
    700: '#C0AAD6',
    800: '#CDBBDE',
    900: '#D9CCE6',
    DEFAULT: '#744B9B',
  },
  ketoneUrine: {
    900: '#2B081A',
    800: '#410C27',
    700: '#6C1341',
    600: '#971B5A',
    500: '#DD4593',
    400: '#E56FAB',
    300: '#E985B8',
    200: '#ED9BC5',
    100: '#F1B1D1',
    50: '#F5C7DE',
    DEFAULT: '#E56FAB',
  },
  ketoneUrineDark: {
    50: '#2B081A',
    100: '#410C27',
    200: '#6C1341',
    300: '#971B5A',
    400: '#DD4593',
    500: '#E56FAB',
    600: '#E985B8',
    700: '#ED9BC5',
    800: '#F1B1D1',
    900: '#F5C7DE',
    DEFAULT: '#DD4593',
  },
  // bloodPressureSystolic: {
  //   900: '#2B0808',
  //   800: '#410C0C',
  //   700: '#6C1313',
  //   600: '#971B1B',
  //   500: '#DD2323',
  //   400: '#E95F5F',
  //   300: '#ED9C9C',
  //   200: '#F5C7C7',
  //   100: '#FBE9E9',
  //   50: '#FDF6F6',
  // },
  // bloodPressureDiastolic: {
  //   900: '#302500',
  //   800: '#472803',
  //   700: '#6C3E13',
  //   600: '#97571B',
  //   500: '#E28A21',
  //   400: '#E9A95F',
  //   300: '#EDC89C',
  //   200: '#F5E0C7',
  //   100: '#FBF3E9',
  //   50: '#FDFAF6',
  // },
  waist: gkiGrey,
  waistDark: gkiGreyDark,
  weight: gkiGrey,
  weightDark: gkiGreyDark,
  calories: gkiGrey,
  caloriesDark: gkiGreyDark,
  vitaminD: gkiGrey,
  vitaminDDark: gkiGreyDark,
  magnesium: gkiGrey,
  magnesiumDark: gkiGreyDark,
  potassium: gkiGrey,
  potassiumDark: gkiGreyDark,
  omega3: gkiGrey,
  omega3Dark: gkiGreyDark,
  omega3epa: gkiGrey,
  omega3epaDark: gkiGreyDark,
  omega3dha: gkiGrey,
  omega3dhaDark: gkiGreyDark,
  zinc: gkiGrey,
  zincDark: gkiGreyDark,
  vitaminB12: gkiGrey,
  vitaminB12Dark: gkiGreyDark,
  selenium: gkiGrey,
  seleniumDark: gkiGreyDark,
  bmi: gkiGrey,
  bmiDark: gkiGreyDark,
  heartRate: gkiGrey,
  heartRateDark: gkiGreyDark,
  heartRateVariability: gkiGrey,
  heartRateVariabilityDark: gkiGreyDark,
  bloodPressureSystolic: gkiGrey,
  bloodPressureSystolicDark: gkiGreyDark,
  bloodPressureDiastolic: gkiGrey,
  bloodPressureDiastolicDark: gkiGreyDark,
};

// These colors have single values, so they should be kept separately from
// `baseColors`. Example usage: text-white, border-transparent, bg-black
const singleValueColors = {
  white: tailwindColors.white,
  black: tailwindColors.black,
  transparent: 'transparent',
  emerald: 'rgb(16, 185, 129)',
  blue: 'rgb(96, 165, 250)',
  violet: 'rgb(139, 92, 246)',
  indigo: 'rgb(99, 102, 241)',
  grey: '#DCD5D1',
};

const allColors = {
  baseColors,
  brandColors,
  singleValueColors,
};

module.exports = { allColors };

