import { getReadingName } from 'domain/domain.utils';
import { Unit } from 'models';

import { Reading, ReadingDto, ReadingValueAndUnitDto } from '../../types';

export const mapToReading = (dto: ReadingDto): Reading => {
  const value = getValue(dto.reading_value);
  const unit = getUnit(dto.reading_unit);

  return {
    id: dto.reading_id,
    type: dto.reading_type,
    sampleType: dto.reading_sample_type,
    readingName: getReadingName(dto.reading_type, dto.reading_sample_type),
    timestamp: dto.reading_timestamp,
    timestampEndedAt: dto.reading_timestamp_ended_at,
    gkiRelatedReadings: mapDtoToGkiRelatedReadings(dto.gki_related_readings),
    meterType: dto.meter_type,
    value,
    unit,
    notes: dto.notes,
    tags: dto.tags,
    source: dto.source,
    deletedAt: dto.deleted_at ?? null,
    serialNumber: dto.serial_number,
    isSynchronized: false,
    timezone: dto.reading_timezone ?? undefined,
    readingSeriesIntervalSeconds: dto.reading_series_interval_seconds,
    readingSeries: dto.reading_series,
  };
};

export const mapDtoToReadingValueAndUnit = (dto: ReadingValueAndUnitDto) => ({
  value: getValue(dto.reading_value),
  unit: getUnit(dto.reading_unit),
});

const getValue = (readingValue: ReadingDto['reading_value']): number =>
  Number(readingValue);

const getUnit = (readingUnit: ReadingDto['reading_unit']) =>
  readingUnit ? readingUnit : Unit.NO_UNIT;

const mapDtoToGkiRelatedReadings = (dto: ReadingDto['gki_related_readings']) =>
  dto
    ? {
        glucose: dto.glucose
          ? {
              id: dto.glucose.reading_id,
              value: getValue(dto.glucose.reading_value),
              unit: getUnit(dto.glucose.reading_unit),
              source: dto.glucose.source,
            }
          : null,
        ketone: dto.ketone
          ? {
              id: dto.ketone.reading_id,
              value: getValue(dto.ketone.reading_value),
              unit: getUnit(dto.ketone.reading_unit),
              source: dto.ketone.source,
            }
          : null,
      }
    : null;
